import { Stack, Box, Typography } from "@mui/material";
import React from "react";
import Signup from "../components/signup";

import { Link, useLocation } from "react-router-dom";

import Otp from "../components/otp";
import CreatePassword from "../components/createPassword";
import ProfileName from "../components/setProfileName";
import TellStatus from "../components/tellStatus";
import ScrollToTopOnMount from "../components/scrolltoview";
import background from "../images/background.png";

export default function Auth() {
    const location = useLocation();
    const props = location.state || {page: 'signup'};

    return(
        <Box disableGutters sx={{ backgroundImage: 'linear-gradient(blue, blue, white);', pt: 0, pb: 8, minHeight: '100vh'}}>
            <ScrollToTopOnMount />     
            <Box display='flex' alignItems='center' justifyContent='center' width='100%' sx={{backgroundImage: 'linear-gradient(to right,rgb(175, 171, 171), blue, blue, blue, rgb(175, 171, 171));'}} >
                <Typography sx={{color: 'white', width: {sm: '50%'}, fontSize: {xs: '1.5rem', sm: '2rem'}}} align="center">Sign up to get your dedicated Nigeria phone number</Typography>
            </Box>   
            <Stack direction='column' alignItems='center'>
            <Box>
                    {(() => {
                        if (props.page === "signup")
                            return <Signup  />
                        else if (props.page === "otp")
                            return <Otp />
                        else if (props.page === "createPassword")
                            return <CreatePassword />
                        else if (props.page === "profileName")
                            return <ProfileName password={props.password} />
                        

                        else if (props.page === "accountCreated")
                            return <TellStatus status='accountCreated' />
                             
                    })()}
                </Box>
            </Stack>
        </Box>
    )
}