
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Auth from './pages/auth';
import Otp from './components/otp';

import reportWebVitals from './reportWebVitals';

import { Route, Routes, BrowserRouter as Router, } from 'react-router-dom';
import './fonts/Helvetica-Font/Helvetica.ttf';
import CreatePassword from './components/createPassword';
import ProfileName from './components/setProfileName';
import NotFound from './pages/notFound';

import { Box } from '@mui/material';
import wa_ic from './images/whatsapp.svg';


// Production (accordcalling.com)
export const BASE_URL_VOIPSWITCH = "https://apps.nativetalk.com.ng:449/VS.WebAPI.Admin/"
export const BASE_URL_VOIPSWITCH2 = "https://apps.nativetalk.com.ng:449/vsservices/api/json/syncreply/"
export const BACKEND = "https://apps.nativetalk.com.ng:453/"
export const EMAILAPP = "https://crm.nativetalkapp.com:447/flsk/"

export const ENDPOINTS = { 
  // Voipswitch
  'changePassword': 'admin.client.password.set',
  'getPassword': 'admin.client.password.get',
  'signup': 'admin.retail.create',

  // emailing
  'accordMail': 'ac-mail',

  // Nativetalk multipurpose backend
  'sendNigerianOtp': 'otp',
}


class App extends React.Component {
  render() {
    return (
      
      <Router>
        <div>
        
          <Routes>
            <Route path='/' element={<Auth />}></Route>
            <Route path='/Otp' element={<Otp />}></Route>
            <Route path='/CreatePassword' element={<CreatePassword />}></Route>
            <Route path='/ProfileName' element={<ProfileName />}></Route>
            {/* <Route path='/Auth' element={<Auth />}></Route> */}          

            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* <a href="https://wa.me/+16075275253">
            <Box component='img' width={50} sx={{
              zIndex: '1500', bottom: '10px', right: '50px'
            }} position='fixed' src={wa_ic} />
          </a> */}
        </div>
      </Router>
      
    )
  }  
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
