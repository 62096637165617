import { Paper, Typography, Box, Stack, getCircularProgressUtilityClass } from "@mui/material";

import Button from "@mui/material/Button";
import { useEffect } from "react";

import { Link as RouterLink, Navigate } from 'react-router-dom';

import { useLocation } from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from "react";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useDeviceLanguage } from 'firebase/auth';
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { BACKEND, ENDPOINTS } from "..";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCToZGRqafjCzvtghI-MPHAP9gC2Y3NXxM",
    // authDomain: "peace-web-47384.firebaseapp.com",
    authDomain: "call.nativetalkapp.com",
    projectId: "peace-web-47384",
    storageBucket: "peace-web-47384.appspot.com",
    messagingSenderId: "496100319044",
    appId: "1:496100319044:web:c914211d835f97b8d20111",
    measurementId: "G-6BWEZH4HFQ"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
//   const analytics = getAnalytics(app);
  const auth = getAuth(app);

export default function Otp() {
    const [otp, setOtp] = useState('')
    const [validOtp, setValidOtp] = useState('')
    const [forceReload, setForceReload] = useState(false)
    const [verified, setVerified] = useState(false)

    const number = useState(sessionStorage.getItem('number'))

    // window.location.reload()    
    const handleChange = (newValue) => {
        setOtp(newValue)
      }
        
    auth.useDeviceLanguage()

    useEffect(() => {
        if (sessionStorage.getItem('country') == "+234") {
            sendNigerianOtp()
        }
        else {
            document.getElementById('recaptchaContainer').style.visibility = 'visible'
            window.recaptchaVerifier = new RecaptchaVerifier('recaptchaContainer', {
                'size': 'normal',
                'callback': (response) => {
                    
                },
                'expired-callback': () => {
        
                }
            }, auth);

            const appVerifier = window.recaptchaVerifier
            signInWithPhoneNumber(auth, sessionStorage.getItem('number'), appVerifier)
            .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            console.log('sms sent')
            console.log(confirmationResult)
            // ...
            }).catch((error) => {
                // grecaptcha.reset(window.recaptchaWidgetId);
                console.log(error)
                console.log('error on sms')
                // Or, if you haven't stored the widget ID:
                window.recaptchaVerifier.render().then(function(widgetId) {
                    // grecaptcha.reset(widgetId);
                });        
            });
        }
    }, [forceReload])

    async function sendNigerianOtp() {
        let response = await axios.get(`${BACKEND}${ENDPOINTS['sendNigerianOtp']}?number=${sessionStorage.getItem('number')}`,
        {'headers': {Authorization: 'b08eb8e882f8fad8df8578caea33d7964e261078'}})
        
        if (response.data['status'] == 'successful') {
            setValidOtp(response.data['otp'])
        }
        else {
            console.log(response)
        }
    }
    
    const signup = (e) => {
        e.preventDefault();
        
        document.getElementById("reverify").style.visibility = "hidden"
        
        if (otp != "") {
            if (sessionStorage.getItem('country') == "+234") {
                if (otp == validOtp) setVerified(true)
                else alert("invalid otp")
            
                document.getElementById("circularProgress").style.visibility = "hidden"
            }

            else {
                if (window.confirmationResult) {
                    document.getElementById("circularProgress").style.visibility = "visible"
                    window.confirmationResult.confirm(otp)
                    .then((result) => {
                        console.log('user is ' + result.user)
                        setVerified(true)
                        document.getElementById("circularProgress").style.visibility = "hidden"
                    })
                    .catch((error) => {
                        document.getElementById("reverify").style.visibility = "visible"
                        console.log(error.message)
                        document.getElementById("circularProgress").style.visibility = "hidden"
                    })
                }    
                else 
                    document.getElementById("reverify").style.visibility = "visible"
            }
            
        }
        
    }

    if (verified)
        return <Navigate to="/" state={{ page: 'createPassword' }} />
    else
    return (
        <Paper component="div"
               sx={{ display: 'flex', flexDirection: 'column',
               py: 10, px: 4, mt: 3, width: {xs: '70vw', sm: '400px'},
               borderRadius:4 }}>
            <Stack direction='row' spacing={1}>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
            </Stack>

            <Typography variant="h5" fontWeight={700} mt={3.5}>Enter Verification Code</Typography>
            
            {(() => {
                if (sessionStorage.getItem('country') != "+234") return (
                    <Typography visibility='' variant="body2" mt={3} color="#2E368F">
                        Verify recaptcha, then input the otp verification code sent to {number}
                    </Typography>
                ) 
                else return (
                    <Typography visibility='' variant="body2" mt={3} color="#2E368F">
                        A verification code has been sent to {number}
                    </Typography>
                )
            })()}
            
            <Stack component='form' onSubmit={(e) => signup(e)} direction='column' pt={6}>
                {/* <Stack justifyContent='space-between' direction='row' sx={{ fontSize: '1.3rem'}}>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>0</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>5</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>8</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                            justifyContent: 'center', width:'60px', height:'60px'}}>6</Paper>
                </Stack> */} 

                <MuiOtpInput display='flex' gap={1} length={6} value={otp} onChange={handleChange} />
                <Button type='submit' id="sign-up-button" color='primary' variant="contained"
                sx={{  mt: 7.5, py: 1.5, backgroundColor: 'blue', textTransform: 'none', width: '100%' }}>
                    Verify
                </Button>
                <Typography id="reverify" visibility='hidden' pt={1} color='red' variant='body2'>reverify recaptcha</Typography>
                 
                <CircularProgress id="circularProgress" sx={{ visibility:"hidden", position: "relative", left: {xs: "120px", md: "150px"}, top: '20px' }} color="primary" />
                
                <Stack mt={4} color="#2E368F" direction="row" justifyContent="space-between">
                    {/* <Typography variant="body2" >
                        Send code again
                    </Typography> */}
                    <RouterLink to="/" state={{page: 'signup'}}>
                        <Typography variant="body2" >
                            Change phone number
                        </Typography>
                    </RouterLink>
                </Stack>
                
            </Stack>
        </Paper>
    )
}